<template>
  <el-card>
    <el-button
      type="primary"
      icon="el-icon-plus"
      size="mini"
      @click="handleAddDetails"
    >添加
    </el-button>
    <el-button
      type="success"
      icon="el-icon-delete"
      size="mini"
      @click="handleDeleteDetails"
    >删除
    </el-button>
    <el-button
      type="danger"
      icon="el-icon-delete"
      size="mini"
      @click="handleDeleteAllDetails"
    >清空
    </el-button>
    <el-button
      type="danger"
      icon="el-icon-delete"
      size="mini"
      @click="handleSubmit"
    >提交
    </el-button>
    <el-table :data="myTable"
              :row-class-name="rowClassName"
              @selection-change="handleSelectionChange"
              ref="tableRef"
    >
      <el-table-column type="selection" align="center"/>
      <el-table-column label="序号" align="center" prop="id"/>
      <el-table-column label="输入内容1" prop="inputValue">
        <template slot-scope="scope">
          <el-input
            placeholder="请输入内容"
            v-model="myTable[scope.row.id-1].inputValue1"
            clearable>
          </el-input>
        </template>
      </el-table-column>
      <el-table-column label="年份" align="center" prop="yearValue">
        <template slot-scope="scope">
          <el-date-picker
            type="year"
            placeholder="选择年"
            format="yyyy 年"
            value-format="yyyy"
            v-model="myTable[scope.row.id-1].yearValue">
          </el-date-picker>
        </template>
      </el-table-column>
      <el-table-column label="下拉框" align="center" prop="selectValue">
        <template slot-scope="scope">
          <el-select
            v-model="myTable[scope.row.id-1].selectValue"
            placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>


<script>
  export default {
    data() {
      return {
        // 详细list
        myTable: [],
        multipleSelection: [],
        options: [{
          value: '选项1',
          label: '黄金糕'
        }, {
          value: '选项2',
          label: '双皮奶'
        }, {
          value: '选项3',
          label: '蚵仔煎'
        }, {
          value: '选项4',
          label: '龙须面'
        }, {
          value: '选项5',
          label: '北京烤鸭'
        }]
      }
    },
    created() {
      console.log('myTable: ' + this.myTable)
    },
    methods: {
      rowClassName({ row, rowIndex }) {
        row.id = rowIndex + 1
      },
      handleSelectionChange(val) {
        this.multipleSelection = val
        console.log(val)
      },
      handleAddDetails() {
        if (this.myTable === undefined) {
          this.myTable = []
        }
        const obj = {
          inputValue1: '',
          yearValue: '2021',
          selectValue: '选项1'
        }
        this.myTable.push(obj)
      },
      // 多选删除
      handleDeleteDetails() {
        if (this.multipleSelection.length === 0) {
          this.$alert('请先选择要删除的数据', '提示', {
            confirmButtonText: '确定'
          })
        } else {
          var _this = this
          // 拿到选中的数据；
          var checkedData = this.multipleSelection
          // 如果选中数据存在
          if (checkedData) {
            // 将选中数据遍历
            checkedData.forEach(function (item, index) {
              // 遍历源数据
              _this.myTable.forEach(function (itemI, indexI) {
                // 如果选中数据跟元数据某一条标识相等，删除对应的源数据
                if (item.id === itemI.id) {
                  _this.myTable.splice(indexI, 1)
                }
              })
            })
          }
          // 清除选中状态
          this.$refs.tableRef.clearSelection()
        }
      },
      handleDeleteAllDetails() {
        this.myTable = []
      },
      handleSubmit() {
        console.log(this.myTable)
      }
    }
  }
</script>